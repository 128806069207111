.wallet-connect .wallet-adapter-button {
    background-color: transparent !important;
    /* Remove background color */
    border: 0.5px solid #5C5C5C !important;
    /* Initial border */
    color: #ffffff !important;
    /* Text color */
    border-radius: 10px !important;
    /* Round the edges */
    background-image: url("https://imagedelivery.net/mvLbuIHfkMEVR9inRhGQfg/96bbec69-f36d-4cb3-6a1c-b9f5862a7300/public") !important;
    background-blend-mode: overlay !important;
    /* Blend the noise with the button background */
    transition: border-color 0.3s ease, background-color 0.3s ease;
    /* Smooth transitions */
    @media screen and (max-width: 48em) {
        padding: 8px 8px;
        font-size: 0.8rem;
    }
}

.wallet-connect .wallet-adapter-button:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    /* Slight white glow */
    border-color: white !important;
    /* White border on hover */
    background-blend-mode: overlay;
    background-image: url("https://imagedelivery.net/mvLbuIHfkMEVR9inRhGQfg/96bbec69-f36d-4cb3-6a1c-b9f5862a7300/public") !important;

}

/* Customize the dropdown list container */
.wallet-adapter-dropdown-list {
    border: 0.5px solid #5C5C5C !important;
    border-radius: 10px !important;
    background-image: url("https://imagedelivery.net/mvLbuIHfkMEVR9inRhGQfg/96bbec69-f36d-4cb3-6a1c-b9f5862a7300/public") !important;
    background-blend-mode: overlay !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
    background-color: #00000080 !important;
}

/* Customize individual items inside the dropdown */
.wallet-adapter-dropdown-list li {
    padding: 12px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 10px;
}

/* Hover effect for items */
.wallet-adapter-dropdown-list li:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    /* Slight white glow */
    background-blend-mode: overlay !important;
    background-image: url("https://imagedelivery.net/mvLbuIHfkMEVR9inRhGQfg/96bbec69-f36d-4cb3-6a1c-b9f5862a7300/public") !important;
}