.nav-link {
    position: relative;
    text-decoration: none;
    padding-bottom: 4px;
    color: #FFAD00;
    /* Default color */
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: #333;
    /* Color change on hover */
}

/* Underline for the hover effect */
.nav-link::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    /* Thicker underline */
    background: #5FF4F4;
    /* Custom underline color */
    transition: width 0.3s ease;
    position: absolute;
    left: 0;
    bottom: 0;
}

/* Full underline width on hover */
.nav-link:hover::after {
    width: 100%;
}

/* Underline for the active (selected) link */
.nav-link.active::after {
    width: 100%;
    background: #5FF4F4;
}

.menu-button {
    background-color: #1B1816;
    color: #fff;
    text-align: left;
    padding: 1rem 2rem;
    font-size: 2rem;
    border: none;
    width: calc(100% - 2rem);
    margin: 0 1rem;
    transition: background-color 0.2s ease;
    border-radius: 1.3rem;
    font-weight: 500;
    padding-left: 3rem;
}

.menu-button:hover {
    color: white !important;
}

.menu-button:focus {
    outline: none;
}