/* Mint Box container styling */
.mint-box {
    background-color: #13100E;
    /* Transparent background */
    border: 0.5px solid #5C5C5C;
    /* Subtle border */
    color: #ffffff;
    /* Text color */
    padding: 24px;
    /* Padding inside the box */
    border-radius: 16px;
    /* Rounded corners */
    background-blend-mode: overlay;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    box-shadow: 0px 0px 40px 5px rgba(0, 255, 255, 0.05);
    /* Extremely soft cyan glow */
    /* Softer glow */
    /* Soft glow with cyan tint */
}

/* Input fields inside the mint box */
.mint-box-input {
    background-color: #1a1a1a;
    /* Darker input background */
    border: 0.5px solid #5C5C5C;
    /* Subtle border */
    border-radius: 12px;
    /* Slightly rounded input corners */
    height: 50px;
    color: #ffffff;
    /* White text color */
    padding: 8px 16px;
    transition: border-color 0.3s ease;
}

/* small button */
.mint-wallet-connect .wallet-adapter-button {
    background-color: transparent !important;
    color: #ffffff !important;
    border: 0.5px solid #5C5C5C;
    border-radius: 8px;
    min-width: 420px !important;
    /* Ensure button doesn't stretch too much */
    text-align: center;
    height: 60px !important;
    /* Set a fixed height */
    background-image: url("https://imagedelivery.net/mvLbuIHfkMEVR9inRhGQfg/96bbec69-f36d-4cb3-6a1c-b9f5862a7300/public") !important;
    background-blend-mode: overlay !important;
    transition: background-color 0.3s ease, border-color 0.3s ease !important;
}


.mint-wallet-connect .wallet-adapter-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* White glow on hover */
    /* border-color: #ffffff; */
    /* White border on hover */
}

/* Mint Button styling */
.mint-box-button {
    background-color: transparent !important;
    color: #ffffff !important;
    border: 0.5px solid #5C5C5C;
    padding: 16px;
    border-radius: 16px;
    width: 100%;
    /* Ensure button doesn't stretch too much */
    text-align: center;
    height: 60px !important;
    /* Set a fixed height */
    background-image: url("https://imagedelivery.net/mvLbuIHfkMEVR9inRhGQfg/96bbec69-f36d-4cb3-6a1c-b9f5862a7300/public") !important;
    background-blend-mode: overlay !important;
    transition: background-color 0.3s ease, border-color 0.3s ease !important;
}

.mint-box-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* White glow on hover */
    /* border-color: #ffffff; */
    /* White border on hover */
}

.claim-box-button {
    background-color: transparent !important;
    color: #ffffff !important;
    border: 0.5px solid #5C5C5C;
    padding: 16px;
    border-radius: 16px;
    width: 100%;
    /* Ensure button doesn't stretch too much */
    text-align: center;
    height: 40px !important;
    /* Set a fixed height */
    background-image: url("https://imagedelivery.net/mvLbuIHfkMEVR9inRhGQfg/96bbec69-f36d-4cb3-6a1c-b9f5862a7300/public") !important;
    background-blend-mode: overlay !important;
    transition: background-color 0.3s ease, border-color 0.3s ease !important;
}

.claim-box-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* White glow on hover */
    /* border-color: #ffffff; */
    /* White border on hover */
}

/* Exchange rate and APR section styling */
.exchange-info {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    color: white;
    /* Gray color for text */
}

/* Transition and hover effects for interactive elements */
.mint-box-input:focus,
.mint-box-button:hover {
    border-color: #ffffff;
    /* Change to white border when focused or hovered */
}