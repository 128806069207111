.wallet-adapter-modal-wrapper {
    border: 0.5px solid #7c7c7c !important;
    border-radius: 16px !important;
    background-image: url("https://imagedelivery.net/mvLbuIHfkMEVR9inRhGQfg/96bbec69-f36d-4cb3-6a1c-b9f5862a7300/public") !important;
    background-blend-mode: overlay !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
    background-color: #181A20 !important;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.2), 0 4px 12px rgba(0, 0, 0, 0.1) !important;
    padding: 12px 20px !important;
}

.wallet-adapter-modal-button-close {
    background: #1C232E !important;
}

.wallet-adapter-modal-container .wallet-adapter-button {
    padding: 12px 20px !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    border-radius: 16px !important;
}

.wallet-adapter-modal-container .wallet-adapter-button:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    /* Slight white glow */
    background-blend-mode: overlay !important;
    background-image: url("https://imagedelivery.net/mvLbuIHfkMEVR9inRhGQfg/96bbec69-f36d-4cb3-6a1c-b9f5862a7300/public") !important;
}